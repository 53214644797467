/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import getfirebase from "../firebase";

const db = getfirebase();

export class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agprofiledata: null,
    };
  }
  componentDidMount() {
    // window.scrollTo(0, 0);

    db.collection("agprofile")
      .doc("SHujA8VWCxUkGDiZKhdo")
      .get()
      .then((doc) => {
        this.setState({
          agprofiledata: doc.data(),
        });
      })
      .catch((error) => console.log(error));
  }
  render() {
    const { agprofiledata } = this.state;
    console.log(agprofiledata);
    if (agprofiledata === null) {
      return <div>Loading...</div>;
    }
    return (
      <div
        className="leading-normal tracking-normal text-white gradient"
        style={{ fontFamily: "Source Sans Pro', sans-serif" }}
      >
        <div>
          <div className="pt-24">
            <div className="container px-3 mx-auto flex flex-wrap md:flex-row items-center">
              <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
                <h1 className="my-4 text-3xl font-bold leading-tight">
                  Truth can be stated in a thousand different ways, yet each
                  one can be true – Swami Vivekananda
                </h1>
                <div className="flex items-center justify-center gap-x-2">
                  <Link
                    id="myP"
                    to="/contactus"
                    className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
                  >
                    Contact Us
                  </Link>
                  <a
                    id="ads"
                    href="https://drive.google.com/file/d/1FyC15Rq3lc2-H1xH4aCZzA7Gkjs-GO9o/view"
                    className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
                  >
                    Recruitment Advertisement!
                  </a>
                </div>
              </div>
              <div className="w-full md:w-3/5 py-6 text-center">
                <img
                  className="w-full md:w-4/5 z-50"
                  src="https://belurmath.org/wp-content/uploads/2018/07/Swami-Vivekananda-Chicago-Address-741x1024.png"
                  style={{ maxHeight: 600 }}
                />
              </div>
            </div>
          </div>
          <div className="relative -mt-12 lg:-mt-24">
            <svg
              viewBox="0 0 1428 174"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g
                  transform="translate(-2.000000, 44.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <path
                    d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                    opacity="0.100000001"
                  />
                  <path
                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                    opacity="0.100000001"
                  />
                  <path
                    d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                    id="Path-4"
                    opacity="0.200000003"
                  />
                </g>
                <g
                  transform="translate(-4.000000, 76.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z" />
                </g>
              </g>
            </svg>
          </div>

          <section className="bg-white border-b py-8">
            <div className="container mx-auto flex flex-wrap pt-4 pb-12">
              <h1
                className="w-full my-2 text-2xl  font-bold leading-tight text-center text-gray-800"
                id="myP"
              >
                Work
              </h1>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <a
                  href="https://services.ecourts.gov.in/ecourtindiaHC/cases/case_no.php?state_cd=18&dist_cd=1&court_code=1&stateNm=Chhattisgarh#"
                  className=" cursor-pointer flex-none p-3 mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow "
                >
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561522540/ag-portal/case_studyy.png"
                      style={{ height: 100 }}
                    />

                    <a
                      id="myP"
                      href="https://services.ecourts.gov.in/ecourtindiaHC/cases/case_no.php?state_cd=18&dist_cd=1&court_code=1&stateNm=Chhattisgarh#"
                      className=" hover:underline text-lg  text-black font-bold rounded-full my-2  "
                    >
                      Case Status
                    </a>
                  </div>
                </a>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <Link
                  to="/order"
                  className=" cursor-pointer flex-none p-3 mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow "
                >
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561450093/ag-portal/law.png"
                      style={{ height: 100 }}
                    />

                    <Link
                      id="myP"
                      to="/order"
                      className=" hover:underline text-lg  text-black font-bold rounded-full my-2  "
                    >
                      Judgements and orders
                    </Link>
                  </div>
                </Link>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <Link
                  to="/emadvocate"
                  className=" cursor-pointer flex-none p-3 mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow "
                >
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561450096/ag-portal/directory.png"
                      style={{ height: 100 }}
                    />

                    <Link
                      id="myP"
                      to="/emadvocate"
                      className=" hover:underline text-lg  text-black font-bold rounded-full my-2  "
                    >
                      Empanelled Advocates
                    </Link>
                  </div>
                </Link>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <Link
                  id="myP"
                  to="/gallery"
                  className=" cursor-pointer flex-none p-3 mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow "
                >
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561450094/ag-portal/reports.png"
                      style={{ height: 100 }}
                    />

                    <Link
                      to="/gallery"
                      className=" hover:underline text-lg  text-black font-bold rounded-full my-2  "
                    >
                      Gallery
                    </Link>
                  </div>
                </Link>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <Link
                  to="/markstatus"
                  className=" cursor-pointer flex-none p-3 mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow "
                >
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561450094/ag-portal/reports.png"
                      style={{ height: 100 }}
                    />

                    <Link
                      id="myP"
                      to="/markstatus"
                      className=" hover:underline text-lg  text-black font-bold rounded-full my-2  "
                    >
                      Marking Status
                    </Link>
                  </div>
                </Link>
              </div>

              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <Link
                  to="/officers"
                  className=" cursor-pointer flex-none p-3 mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow "
                >
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561450094/ag-portal/reports.png"
                      style={{ height: 100 }}
                    />

                    <Link
                      id="myP"
                      to="/officers"
                      className=" hover:underline text-lg  text-black font-bold rounded-full my-2  "
                    >
                      Law Officers
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
          </section>

          <svg
            className="wave-top"
            viewBox="0 0 1439 147"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <g
                transform="translate(-1.000000, -14.000000)"
                fillRule="nonzero"
              >
                <g className="wave" fill="#f8fafc">
                  <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z" />
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                  <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path
                      d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                      opacity="0.100000001"
                    />
                    <path
                      d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                      opacity="0.100000001"
                    />
                    <path
                      d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                      opacity="0.200000003"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <section className="container mx-auto text-center py-6 mb-12">
            <div className="container px-3 mx-auto flex flex-wrap md:flex-row items-center">
              <div className="w-2/4">
                <h1
                  className="w-full my-2 text-2xl font-bold leading-tight text-center text-white"
                  id="myP"
                >
                  {agprofiledata.fullname}
                </h1>
                <div className="w-full mb-4">
                  <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t" />
                </div>
                <h3 className="my-4 text-1xl leading-tight" id="myP">
                  {agprofiledata.heading}
                </h3>
                <Link
                  to="/agprofile"
                  id="myP"
                  style={{ fontSize: 14 }}
                  className="mx-auto sm:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
                >
                  Know More
                </Link>
              </div>
              <div className="w-2/4">
                <img
                  className="w-full md:w-4/5 z-50"
                  src={`${agprofiledata.image}`}
                  // src="https://res.cloudinary.com/dcr2pfgxy/image/upload/v1561455545/ag-portal/secons-ag.png"
                  style={
                    {
                      //  width: 300, height: 300
                    }
                  }
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default LandingPage;
